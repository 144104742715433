import { twentyFourHoursInMs, tenMinsInMs } from '@/utils/query.config'
import AdoriServiceV6 from '@/services/adori_v6'
import store from '@/store'
import { useMutation, useQuery } from 'vue-query'

// QueryKeys
export enum GamificationQueryKeys {
  GAMIFICATION_BADGES = 'GAMIFICATION_BADGES',
  GAMIFICATION_MYBADGES = 'GAMIFICATION_MYBADGES',
  GAMIFICATION_MYSCORE = 'GAMIFICATION_MYSCORE',
  GAMIFICATION_LEADERBOARD = 'GAMIFICATION_LEADERBOARD',
}

// Queries
function fetchGamificationBadges(networkId: string) {
  return AdoriServiceV6.fetchAllBadges(networkId)
}
function fetchGamificationMyBadges(networkId: string) {
  return AdoriServiceV6.fetchMyBadges(networkId)
}
function fetchGamificationMyScore(networkId: string) {
  return AdoriServiceV6.fetchMyScore(networkId)
}
function fetchGamificationleaderboard(networkId: string) {
  return AdoriServiceV6.fetchLeaderboard(networkId)
}

//Query Hooks
export const useGamificationBadges = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([GamificationQueryKeys.GAMIFICATION_BADGES, networkId], () => fetchGamificationBadges(networkId), {
    staleTime: twentyFourHoursInMs,
    cacheTime: twentyFourHoursInMs,
    retry: false,
  })
}

export const useGamificationMyBadges = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery(
    [GamificationQueryKeys.GAMIFICATION_MYBADGES, networkId],
    () => fetchGamificationMyBadges(networkId),
    {
      staleTime: tenMinsInMs,
      cacheTime: tenMinsInMs,
      retry: false,
    }
  )
}

export const useGamificationMyScore = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([GamificationQueryKeys.GAMIFICATION_MYSCORE, networkId], () => fetchGamificationMyScore(networkId), {
    staleTime: tenMinsInMs,
    cacheTime: tenMinsInMs,
    retry: false,
  })
}

export const useGamificationLeaderboard = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery(
    [GamificationQueryKeys.GAMIFICATION_LEADERBOARD, networkId],
    () => fetchGamificationleaderboard(networkId),
    {
      staleTime: tenMinsInMs,
      cacheTime: tenMinsInMs,
      retry: false,
    }
  )
}
