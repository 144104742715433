



























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ components: {} })
export default class BadgeCard extends Vue {
  @Prop(String) title!: string
  @Prop(String) content!: string
  @Prop(Number) points!: number
  @Prop(Boolean) isListing!: boolean
  @Prop(Number) count!: number
  @Prop(String) imageUrl!: string

  async mounted() {}
}
