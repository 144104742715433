var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card-form" }, [
      _c("div", { staticClass: "card-list" }, [
        _c("div", { staticClass: "flex justify-center" }, [
          _c("img", { attrs: { src: _vm.imageUrl, width: "100" } }),
        ]),
      ]),
      _c("div", { staticClass: "card-info" }, [
        _c("div", { staticClass: "points" }, [
          _c("div", { staticClass: "xp" }, [
            _vm._v("+" + _vm._s(_vm.points) + " XP"),
          ]),
        ]),
        !_vm.isListing
          ? _c("div", { staticClass: "wrap" }, [
              _c("span", { staticClass: "decorator" }, [
                _vm._v("x" + _vm._s(_vm.count)),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "pa4" }, [
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _c("p", [_vm._v(_vm._s(_vm.content))]),
        ]),
        _c("img", {
          staticClass: "wave-bg",
          attrs: { src: require("@/assets/gamification/wave.svg") },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }